<template>
  <div class="page">
    <div class="card login-card">
      <div class="card-body">
        <div class="text-center pt-5 pb-3">
          <img src="@/assets/pb_logo_1_remove_bg_100x125.png">
        </div>
        <div class="text-white">
          <h4><center>パスワードの再設定</center></h4>
          <p class="text-center">ご登録のメールアドレスに、パスワード再設定用のURLを送信いたします。</p>
        </div>
        <b-form @submit.prevent="onSubmit">
          <div class="biz-form-group">
            <div class="biz-form-body">
              <b-form-group class="biz-input-group">
                <b-form-input type="email" v-model="form.email" name="email" placeholder="メールアドレス">
                </b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="text-center mt-4">
            <j-button buttonType="submit" class="login-button" variant="primary">送信</j-button>
          </div>
          <div class="text-center mt-4">
            <router-link to="/login" class="reset-password-link">
              <font-awesome-icon icon="arrow-left"/>
              ログイン画面に戻る
            </router-link>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
  import Login from '@/model/Login.js';
  export default {
    components: {},
    data: function(){
      return { form: {} }
    },
    methods: {
      onSubmit() {
        Login.resetPassword(this.form).then(() => {
          this.$successMessage("メールアドレス宛にパスワードリセットのメールをお送りしました。数分経ってもメールが届かない場合はご登録のメールアドレスをお確かめの上、再度お試しください。");
          this.$router.push('/login');
        }).catch(reason => this.$errorMessage(undefined, {reason}));
      },
    }
  }
</script>

<style lang="scss" scoped>
  .login-card{
    background:rgba(35, 47, 62, 1);
    max-width: 450px;
    margin: 10px auto;
    border-radius: 1rem;
    .card-body {
      padding: 1rem 2.5rem 1rem 2.5rem;
      input {
        padding: .375rem .75rem;
        height: 38px;
        line-height: 1.5;
      }
    }
  }
  .login-button{
    width: 100%;
    padding: .8rem 1rem;
    background: #1a9bd5;
    border:none;
    box-shadow: none;
    transition: all .2s ease-in;
    &:hover{
      background: #559f59;
    }
  }
</style>